<!--
 * @Author: wenju.liu wenju.liu@softline.sh.cn
 * @Date: 2022-05-16 10:59:54
 * @LastEditors: wenju.liu wenju.liu@softline.sh.cn
 * @LastEditTime: 2022-05-16 12:03:31
 * @FilePath: /original-ui/src/components/ALink/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="fontSize14 contentLink">
    <img src="@/assets/img/tip.jpg" alt="" srcset="" />
    <div class="textPs">
      <p class="textP1">About to jump to an external site.</p>
      <p class="textP2">Security unknow,whether to continue</p>
      <p class="textP3">{{ aUrl }}</p>
    </div>

    <div :class="isApp == 1 ? 'appstyle': 'buttonFlex'">
      <el-button class="button" @click="$emit('close')">Close</el-button>
      <el-button class="button" type="primary" @click="handleLink" style="padding:12px 0px">
        To CONTINUE TO/继续前往
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ALink",
  props: {
    aUrl: {
      type: String,
      default: "",
    },
    isApp:{
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    handleLink() {
      window.open(this.aUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.appstyle{
  button{
    width:200px;
    margin-left: 35px;
    margin-bottom: 10px;
  }
}

.contentLink {
  // width: 600px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  img {
    width: 100px;
  }
  .buttonFlex {
    display: flex;
    justify-content: space-around;
    .button {
      width: 218px;
    }
  }
  .textPs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .textP1 {
      color: #333333;
    }
    .textP2 {
      color: #888888;
    }
    .textP3 {
      color: #419eff;
      margin: 15px 0;
    }
  }
}
</style>